import cn from "classnames";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import features from "features";

import IconButton from "components/buttons/IconButton/IconButton";

import CloseIcon from "assets/icons/close.svg";
import CloseSmallIcon from "assets/icons/close-small.svg";

import styles from "./modal-wrapper.module.scss";

interface ModalWrapperInterface {
  children: any;
  className?: string | Modal.Classes | undefined;
  overlayClassName?: string | Modal.Classes | undefined;
  disableClose?: boolean;
  onHide?: () => void;
  isSmall?: boolean;
}

const ModalWrapper = ({
  children,
  className,
  overlayClassName,
  disableClose,
  onHide,
  isSmall
}: ModalWrapperInterface): JSX.Element => {
  const dispatch = useDispatch();

  const onClose = () => {
    if (onHide) onHide();
    if (!disableClose) dispatch(features.modal.actions.hideModal());
  };

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      className={cn(styles["react-modal"], className)}
      overlayClassName={cn(styles["react-modal-overlay"], overlayClassName)}
      ariaHideApp={false}
      bodyOpenClassName="overflow-hidden"
      preventScroll={true}
    >
      <div className={styles["react-modal__wrapper"]}>
        {!disableClose && (
          <IconButton
            icon={isSmall ? CloseSmallIcon : CloseIcon}
            className={cn(styles["react-modal__close-btn"], {
              [styles["react-modal__close-small-btn"]]: isSmall
            })}
            alt="close"
            onClick={onClose}
          />
        )}
        <div
          className={cn(styles["react-modal__body"], {
            [styles["react-modal__body-small"]]: isSmall
          })}
        >
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default ModalWrapper;
