import { useSelector } from "react-redux";

import { RootStateInterface } from "reducer";

import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";

import CheckIcon from "assets/icons/check.svg"

import styles from "./success-modal.module.scss";

interface SuccessModalInterface {
  title?: string;
  description?: string;
  disableClose?: boolean;
  acceptButtonLabel?: string;
  acceptButtonAction?: () => void;
}

const SuccessModal = () => {
  const {
    title,
    description,
    disableClose,
    acceptButtonLabel,
    acceptButtonAction
  } = useSelector<RootStateInterface, SuccessModalInterface>(
    (state) => state.modal.modalProps
  );

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      disableClose={disableClose}
      isSmall
    >
      <div className={styles["success-modal"]}>
        <div className={styles["success-modal__text-container"]}>
          <h2 className={styles["success-modal__title"]}>{title}</h2>
          <div className={styles["success-modal__description-container"]}>
            <img src={CheckIcon} alt="Done"/>
            <h4 className={styles["success-modal__description"]}>
              {description ?? "Success"}
            </h4>
          </div>
        </div>
        <div className={styles["success-modal__controls-wrapper"]}>
          <PrimaryButton
            type="button"
            onClick={acceptButtonAction}
            textSize={14}
          >
            {acceptButtonLabel ?? "Done"}
          </PrimaryButton>
        </div>
      </div>
    </ModalWrapper>
  );
};
export default SuccessModal;
