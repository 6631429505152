import { createSlice } from "@reduxjs/toolkit";
import { AREA_COLORS, DEFAULT_LAYERS_LIST, LayerInterface } from "constant";

export interface MapLayerInterface extends LayerInterface {
  params?: string;
  icon?: string;
  isActive?: boolean;
  bounds?: number[];
  center?: number[];
  maxzoom?: number;
  opacity?: number;
  rescale_min?: number;
  rescale_max?: number;
}
export interface MapAriesInterface {
  name: string;
  color: string;
  isActive: boolean;
  alias: string;
}

interface GeoDataInterface {
  type: string;
  coordinates: number[];
}

export interface MarkerInterface {
  color: string;
  created_at: string;
  geo_data: GeoDataInterface;
  id: string;
  layer: string;
  name: string;
  additional: {
    probability: number;
  };
}

export interface PolygonInterface {
  coordinates: number[];
  id: string;
  alias: string;
  color: string;
  text: string;
  isActive?: boolean;
}

export interface MapStateInterface {
  title: string;
  id: string;
  layers: MapLayerInterface[];
  aries: MapAriesInterface[];
  isLoadingPolygons: boolean;
  isLoading: boolean;
  isLoadingMarkers: boolean;
  isLoadingMarkerDetails: boolean;
  polygons: MarkerInterface[];
  markers: MarkerInterface[];
  markerDetails: MarkerDetailsInterface;
  isMarkerDeleteLoading: boolean;
  isCreateLayerLoadingLoading: boolean;
}

export interface MarkerDetailsInterface {
  name: string;
  id: string;
  imgs: string[];
  geo_data: {
    type: string;
    coordinates: number[];
  };
  isActive?: boolean;
}

const initialState: MapStateInterface = {
  isLoading: false,
  isLoadingMarkers: false,
  isLoadingMarkerDetails: false,
  isLoadingPolygons: false,
  isMarkerDeleteLoading: false,
  isCreateLayerLoadingLoading: false,
  title: "",
  id: "",
  layers: DEFAULT_LAYERS_LIST,
  aries: [],
  markers: [],
  polygons: [],
  markerDetails: null
};

//TODO delete
const markerLayer = "rgb";

export const mainSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    fetchProjectRequest: (state) => {
      state.isLoading = true;
    },
    fetchProjectSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.title = payload.name;
      state.id = payload.id;

      const resLayers = payload?.layers ?? [];

      let combinedArray = [...state.layers, ...resLayers].reduce((acc, obj) => {
        acc[obj.name] = {
          ...obj,
          isInit: acc[obj.name] ? true : obj.isInit
        };
        return acc;
      }, {});
      combinedArray = Object.values(combinedArray);

      state.layers = combinedArray;
    },
    fetchProjectError: (state) => {
      state.isLoading = false;
    },

    startInferenceRequest: (state) => {
      state.isLoading = true;
    },
    startInferenceSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.isLoadingMarkers = false;
      const newMarkers =
        payload?.map((marker) => {
          return {
            ...marker,
            layer: "rgb",
            color: state.layers.find((layer) => {
              return layer.alias === markerLayer;
            }).color
          };
        }) ?? [];
      state.markers = [...state.markers, ...newMarkers];
      state.polygons = state.markers;
    },
    startInferenceError: (state) => {
      state.isLoading = false;
    },
    // fetchLayersRequest: (state) => {
    //   state.isLoading = true;
    // },
    // fetchLayersSuccess: (state, { payload }) => {
    //   state.isLoading = false;
    //   state.layers = payload.map((layer) => {
    //     return { ...layer, isActive: layer.is_default };
    //   });
    // },
    // fetchLayersError: (state) => {
    //   state.isLoading = false;
    // },
    fetchAriesRequest: (state) => {
      state.isLoading = true;
    },
    fetchAriesSuccess: (state, {}) => {
      //TODO colors from back-end
      state.isLoading = false;
      state.aries = AREA_COLORS;
    },
    fetchAriesError: (state) => {
      state.isLoading = false;
    },

    fetchMarkersRequest: (state) => {
      state.isLoadingMarkers = true;
    },
    fetchMarkersSuccess: (state, { payload }) => {
      state.isLoadingMarkers = false;
      const newMarkers =
        payload.points?.map((marker) => {
          return {
            ...marker,
            layer: "rgb",
            color: state.layers.find((layer) => {
              return layer.alias === markerLayer;
            }).color
          };
        }) ?? [];
      state.markers = [...state.markers, ...newMarkers];
      state.polygons = state.markers;
    },
    fetchMarkersError: (state) => {
      state.isLoadingMarkers = false;
    },
    updateMarkersByOrthophoto: (state, { payload }) => {
      state.isLoadingMarkers = false;
      const newMarkers = payload.map((marker) => {
        return {
          ...marker,
          color: state.layers.find((layer) => layer.alias === markerLayer).color
        };
      });
      state.markers = newMarkers;
      state.polygons = payload;
    },
    fetchPolygonsRequest: (state) => {
      state.isLoadingPolygons = true;
    },
    fetchPolygonsSuccess: (state, { payload }) => {
      state.isLoadingPolygons = false;
      state.polygons = payload;
    },
    fetchPolygonsError: (state) => {
      state.isLoadingPolygons = false;
    },

    fetchMarkerDetailsRequest: (state) => {
      state.isLoadingMarkerDetails = true;
    },
    fetchMarkerDetailsSuccess: (state, { payload }) => {
      state.isLoadingMarkerDetails = false;
      state.markerDetails = { ...payload, isActive: true };
    },
    fetchMarkerDetailsError: (state) => {
      state.isLoadingMarkerDetails = false;
    },
    clearMarkerDetails: (state) => {
      state.markerDetails = { ...state.markerDetails, isActive: false };
    },

    fetchDeleteMarkerRequest: (state) => {
      state.isMarkerDeleteLoading = true;
    },
    fetchDeleteMarkerSuccess: (state, { payload }) => {
      state.isMarkerDeleteLoading = false;
      state.markerDetails = null;
      const newMarkers = state.markers
        .filter((marker) => String(marker.id) !== String(payload.message))
        .map((marker) => {
          return {
            ...marker,
            color: state.layers.find((layer) => layer.alias === markerLayer)
              .color
          };
        });

      state.markers = newMarkers;
    },
    fetchDeleteMarkerError: (state) => {
      state.isMarkerDeleteLoading = false;
    },

    toggleLayerIsActive: (state, { payload }) => {
      state.layers = state.layers.map((item) =>
        item.alias === payload.params.alias
          ? { ...item, isActive: !item.isActive }
          : item
      );
    },
    toggleAriaIsActive: (state, { payload }) => {
      state.aries = state.aries.map((item) =>
        item.alias === payload
          ? { ...item, isActive: payload.isActive ?? !item.isActive }
          : item
      );
    },

    createLayerRequest: (state) => {
      state.isCreateLayerLoadingLoading = true;
    },
    createLayerSuccess: (state) => {
      state.isCreateLayerLoadingLoading = false;
    },
    createLayerError: (state) => {
      state.isCreateLayerLoadingLoading = false;
    },

    setLayerParams: (state, { payload }) => {
      state.layers = state.layers.map((item) => {
        return item.alias === payload.alias
          ? { ...item, params: payload.params }
          : item;
      });
    },
    setLayerOpacity: (state, { payload }) => {
      state.layers = state.layers.map((item) => {
        return item.alias === payload.alias
          ? { ...item, opacity: payload.opacity }
          : item;
      });
    }
  }
});

export const { actions, reducer } = mainSlice;
