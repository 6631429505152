import L, { LatLngTuple } from "leaflet";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { GEO_VISION_API_URL, KYIV_COORDINATES } from "constant";
import features from "features";
import { getToken } from "helpers/jwt";
import { RootStateInterface } from "reducer";
import { MapStateInterface } from "../ducks";

import ZoomBtns from "components/ZoomBtns/ZoomBtns";
import MapLayers from "../components/MapLayers/MapLayers";
import MapMarkerDetails from "../components/MapMarkerDetails/MapMarkerDetails";
import MapMarkers from "../components/MapMarkers/MapMarkers";
import MapPolygons from "../components/MapPolygons/MapPolygons";
import MapProjectName from "../components/MapProjectName/MapProjectName";
import MapSearchPoint from "../components/MapSearchPoint/MapSearchPoint";

import styles from "./map-page.module.scss";

const MapPage = () => {
  const mapRef = useRef(null);
  const [init, setInit] = useState(false);
  const dispatch = useDispatch();
  const [perimeterBounds, setPerimeterBounds] = useState<LatLngTuple[] | null>(
    null
  );

  const { id } = useParams();

  const {
    title,
    layers,
    aries,
    markers,
    markerDetails,
    isLoadingMarkerDetails,
    isMarkerDeleteLoading,
    isLoading
  } = useSelector<RootStateInterface, MapStateInterface>((state) => state.map);

  const selectedLayers = useMemo(
    () => layers.filter((layer) => layer.isActive),
    [layers]
  );
  const selectedAries = useMemo(
    () => aries.filter((aria) => aria.isActive),
    [aries]
  );
  const selectedMarkers = useMemo(
    () =>
      markers.filter(
        (marker) =>
          selectedLayers.find(
            (selectedLayer) => selectedLayer.alias === marker.layer
          ) || marker.layer === "All"
      ),
    [selectedLayers, markers]
  );

  const initData = useCallback(() => {
    // dispatch(features.map.actions.fetchLayersRequest());
    dispatch(features.map.actions.fetchAriesRequest());
    setInit(true);
  }, [dispatch]);

  useEffect(() => {
    if (!init) {
      initData();
    }
  }, [init, initData]);

  const defaultActiveLayer = useMemo(() => {
    return layers?.find((item) => item.isInit);
  }, [layers]);

  const mapCenter = useMemo(() => {
    const center = defaultActiveLayer?.center;
    return center ? { lng: center[0], lat: center[1] } : null;
  }, [defaultActiveLayer]);

  const mapZoom = useMemo(() => {
    const zoom = defaultActiveLayer?.center?.[2];
    const maxNativeZoom = defaultActiveLayer?.[0]?.maxzoom;

    return {
      zoom: zoom > 0 ? zoom : 9,
      maxNativeZoom: maxNativeZoom > 0 ? maxNativeZoom : 26
    };
  }, [defaultActiveLayer]);

  useEffect(() => {
    dispatch(features.map.actions.fetchProjectRequest({ params: { id } }));
  }, [dispatch, id]);

  useEffect(() => {
    if (isLoading)
      dispatch(
        features.modal.actions.showModal({
          modalType: "PRELOADER",
          modalProps: {
            title: "Processing",
            loading: true,
            disableClose: true
          }
        })
      );
    else
      dispatch(
        features.modal.actions.hideModal({
          modalType: "PRELOADER"
        })
      );
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (layers.length === 0) return () => {};
  }, [layers]);

  const setView = (...args) => {
    if (mapRef.current) {
      mapRef.current.setView(...args);
    }
  };

  const createPerimeter = (
    point1: LatLngTuple,
    point2: LatLngTuple,
    point3: LatLngTuple,
    point4: LatLngTuple
  ) => {
    const bounds: LatLngTuple[] = [point1, point2, point3, point4];
    setPerimeterBounds(bounds);

    const avgLat =
      bounds.reduce((sum, item) => sum + item[0], 0) / bounds.length;
    const avgLng =
      bounds.reduce((sum, item) => sum + item[1], 0) / bounds.length;

    mapRef.current.setView({ lat: avgLat, lng: avgLng }, 18);
  };

  const removePerimeter = () => {
    setPerimeterBounds(null);
  };

  useEffect(() => {
    (window as any).createPerimeter = createPerimeter;
    (window as any).removePerimeter = removePerimeter;
  }, []);

  return (
    <div className={styles["map-page"]}>
      {title && (
        <MapProjectName name={title} className={styles["project-title"]} />
      )}
      <MapLayers layers={layers} aries={aries} setView={setView} />
      {title && (
        <MapContainer
          zoom={mapZoom.zoom}
          zoomControl={false}
          center={mapCenter ? mapCenter : KYIV_COORDINATES}
          className={styles["map"]}
          pmIgnore={false}
          maxZoom={28}
          ref={mapRef}
        >
          <TileLayer
            url="https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            maxNativeZoom={20}
            maxZoom={30}
          />
          {selectedLayers.map((item) => (
            <TileLayer
              accessToken={getToken()}
              url={`${GEO_VISION_API_URL}/projects/${id}/layers/${
                item.alias
              }/tiles/{z}/{x}/{y}${item.params ?? ""}`}
              bounds={
                item?.bounds
                  ? new L.LatLngBounds([
                      [item?.bounds[1], item?.bounds[0]],
                      [item?.bounds[3], item?.bounds[2]]
                    ])
                  : null
              }
              maxNativeZoom={mapZoom.maxNativeZoom}
              maxZoom={30}
              opacity={(item.opacity ?? 100) / 100}
              detectRetina={true}
              key={item.alias}
            />
          ))}
          {selectedMarkers.length > 0 && (
            <MapMarkers
              markers={selectedMarkers}
              // TODO remove opacity
              // isDisabledDefault={
              //   !layers.find((item) => item.alias === "All")?.isActive
              // }
              isDisabledDefault={false}
              activeMarkerId={markerDetails?.id}
            />
          )}
          {selectedAries?.length > 0 && selectedMarkers.length > 0 && (
            <MapPolygons
              aries={selectedAries}
              markers={selectedMarkers}
              // isDisabledDefault={
              //   !layers.find((item) => item.alias === "All")?.isActive
              // }
            />
          )}
          <ZoomBtns
            zoomIn={() => mapRef.current.zoomIn()}
            zoomOut={() => mapRef.current.zoomOut()}
          />
          <MapSearchPoint />
          {perimeterBounds && (
            <Polygon positions={perimeterBounds} color="blue" />
          )}
        </MapContainer>
      )}
      <MapMarkerDetails
        activeMarker={selectedMarkers.find(
          (marker) => marker.id === markerDetails?.id
        )}
        markerDetails={markerDetails}
        isLoadingMarkerDetails={isLoadingMarkerDetails}
        isMarkerDeleteLoading={isMarkerDeleteLoading}
        projectId={id}
        layers={layers}
      />
    </div>
  );
};

export default MapPage;
