import { useSelector } from "react-redux";

import { RootStateInterface } from "reducer";
import { IModalState } from "scenes/Modal/ducks";

import PreloaderModal from "./PreloaderModal/PreloaderModal";
import DeleteModal from "./DeleteModal/DeleteModal";
import FormModal from "./FormModal/FormModal";
import SuccessModal from "./SuccessModal/SuccessModal";

const MODAL_COMPONENTS = {
  PRELOADER: PreloaderModal,
  DELETE: DeleteModal,
  FORM: FormModal,
  SUCCESS: SuccessModal
};

const ModalRoot = () => {
  const { modalType, modalProps } = useSelector<
    RootStateInterface,
    IModalState
  >((state) => state.modal);

  if (!modalType) {
    return <></>;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return <SpecificModal {...modalProps} />;
};

export default ModalRoot;
