import { MapProvider } from "services/ApiServiceProviders";

export const getProjects = ({ params }) => {
  return MapProvider.client
    .get(`/projects?limit=${params.limit}&skip=${params.skip}`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const getProject = ({ params }) => {
  return MapProvider.client
    .get(`/projects/${params.id}`)
    .then((response) => response)
    .catch((error) => error.response);
};

export const addProject = ({ fields }) => {
  return MapProvider.client.post(`/projects`, fields);
};

export const updateProject = ({ params, fields }) => {
  return MapProvider.client.put(`/projects/${params.id}`, fields);
};
export const removeProject = ({ params }) => {
  return MapProvider.client.delete(`/projects/${params.id}`);
};
