import styles from "./main-util.module.scss";

export interface MainUtilInterface {
  title: string;
  link: string;
  icon: string;
  target?: string;
}

const MainUtil = ({ title, link, icon, target }: MainUtilInterface) => {
  return (
    <a className={styles["main-util__wrapper"]} href={link} target={target}>
      <div className={styles["main-util__icon"]}>
        <img src={icon} alt={`${title} logo`}></img>
      </div>
      <div className={styles["main-util__title"]}>{title}</div>
    </a>
  );
};

export default MainUtil;
