import { createSlice } from "@reduxjs/toolkit";
import { LayerAlias } from "constant";

export interface ImageDetectionStateInterface {
  isUploadingImageInferenceLoading: boolean;
  detections: {
    [LayerAlias.RGB]: any[];
    [LayerAlias.MULTISPECTRAL]: any[];
  };
}

const initialState: ImageDetectionStateInterface = {
  isUploadingImageInferenceLoading: false,
  detections: {
    [LayerAlias.RGB]: [],
    [LayerAlias.MULTISPECTRAL]: []
  }
};

export const mainSlice = createSlice({
  name: "imageDetection",
  initialState,
  reducers: {
    fetchUploadImageInferenceRequest: (state) => {
      state.isUploadingImageInferenceLoading = true;
    },
    fetchUploadImageInferenceSuccess: (state, { payload }) => {
      state.isUploadingImageInferenceLoading = false;
      state.detections[payload.params.alias] = payload.data;
    },
    fetchUploadImageInferenceError: (state) => {
      state.isUploadingImageInferenceLoading = false;
    },

    resetDetections: (state, { payload }) => {
      state.detections[payload.alias] = [];
    }
  }
});

export const { actions, reducer } = mainSlice;
