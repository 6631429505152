import { EXTERNAL_API_URL, GEO_VISION_API_URL, ODM_API_URL } from "../constant";

import ApiProvider from "./ApiProvider";

export const ODMProvider = ApiProvider(ODM_API_URL);

export const MapProvider = ApiProvider(GEO_VISION_API_URL);

export const ExternalProvider = ApiProvider(EXTERNAL_API_URL);

// TODO create more elegant solution :D
export const updateProvidersToken = (token) => {
  ODMProvider.setInterceptors(token);
  MapProvider.setInterceptors(token);
  ExternalProvider.setInterceptors(token);
};
