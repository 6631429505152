import { createSlice } from "@reduxjs/toolkit";

export interface IModalState {
  modalType: string | null;
  modalProps: any;
  isModalOpened: boolean;
}

export const initialState: IModalState = {
  modalType: null,
  modalProps: {},
  isModalOpened: false
};

export const mainSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.modalType = action.payload.modalType;
      state.modalProps = action.payload.modalProps;
      state.isModalOpened = true;
    },
    hideModal: (state) => {
      state.modalType = initialState.modalType;
      state.modalProps = initialState.modalProps;
      state.isModalOpened = false;
    }
  }
});

export const { actions, reducer } = mainSlice;
