import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import features from "features";
import { formatDate } from "helpers/formatDate";
import { ProjectInterface } from "scenes/MainPage/ducks";

import FormField from "components/inputs/FormField/FormField";
import IconButton from "components/buttons/IconButton/IconButton";

import PencilIcon from "assets/icons/pencil.svg";
import Trash2Icon from "assets/icons/trash-2.svg";

import styles from "./project-preview.module.scss";

interface ProjectPreviewInterface extends ProjectInterface {
  updateProjectsList?: () => void;
}

const ProjectPreview = ({
  name,
  created_at,
  id,
  updateProjectsList
}: ProjectPreviewInterface) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onDelete = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "DELETE",
        modalProps: {
          title: "Delete project?",
          subtitle: `Are you sure you want to delete project "${name}"? This action cannot be undone.`,
          onAcceptClick: () =>
            dispatch(
              features.main.actions.deleteProjectRequest({
                params: {
                  id
                },
                onSuccess: () => {
                  updateProjectsList();
                  dispatch(
                    features.modal.actions.hideModal({
                      modalType: "DELETE"
                    })
                  );
                }
              })
            ),
          onDeclineClick: () =>
            dispatch(
              features.modal.actions.hideModal({
                modalType: "DELETE"
              })
            )
        }
      })
    );
  }, [dispatch, id]);

  const handleFormSubmit = useCallback(
    (values) => {
      dispatch(
        features.main.actions.renameProjectRequest({
          params: {
            id
          },
          fields: values,
          onSuccess: () => {
            dispatch(
              features.modal.actions.showModal({
                modalType: "SUCCESS",
                modalProps: {
                  title: "Rename project",
                  acceptButtonAction: () => {
                    dispatch(
                      features.modal.actions.hideModal({
                        modalType: "SUCCESS"
                      })
                    );
                  }
                }
              })
            );
          }
        })
      );
    },
    [dispatch, id]
  );

  const onRename = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "FORM",
        modalProps: {
          title: "Rename project",
          description: "Please enter new name.",
          initialValues: { name: "" },
          onSubmit: handleFormSubmit,
          validationSchema: Yup.object().shape({
            name: Yup.string().required("Project name is required")
          }),
          acceptButtonLabel: "Save",
          declineButtonAction: () =>
            dispatch(
              features.modal.actions.hideModal({
                modalType: "FORM"
              })
            ),
          formContent: (
            <>
              <FormField name="name" placeholder="New name" />
            </>
          )
        }
      })
    );
  }, [dispatch, id, name]);

  const link = useMemo(() => {
    return `/map/${id}`;
  }, [id]);

  const handleProjectClick = () => {
    navigate(link);
  };

  const optionsList = useMemo(() => {
    return [
      { title: "Edit", icon: PencilIcon, action: onRename },
      { title: "Delete", icon: Trash2Icon, action: onDelete }
    ];
  }, [onDelete, onRename]);

  return (
    <div onClick={handleProjectClick} className={styles["project"]}>
      <div className={styles["project__title"]}>{name}</div>
      <div className={styles["project__date"]}>{formatDate(created_at)}</div>
      <div className={styles["project__actions-container"]}>
        {optionsList.length > 0 &&
          optionsList.map((option, index) => (
            <IconButton
              icon={option.icon}
              className={styles["project__actions-button"]}
              onClick={(e) => {
                e.stopPropagation();
                option.action();
              }}
              key={`${option.title}:${index}`}
            />
          ))}
      </div>
    </div>
  );
};
export default ProjectPreview;
