import { useSelector } from "react-redux";

import { RootStateInterface } from "reducer";

import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";
import LightButton from "components/buttons/LightButton/LightButton";

import styles from "./delete-modal.module.scss";

interface DeleteModalInterface {
  title?: string;
  subtitle?: string;
  disableClose?: boolean;
  onAcceptClick?: () => void;
  onDeclineClick?: () => void;
}

const DeleteModal = () => {
  const { title, disableClose, subtitle, onAcceptClick, onDeclineClick } =
    useSelector<RootStateInterface, DeleteModalInterface>(
      (state) => state.modal.modalProps
    );

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      disableClose={disableClose}
      isSmall
    >
      <div className={styles["delete-modal"]}>
        <div className={styles["delete-modal__text-container"]}>
          <h2 className={styles["delete-modal__title"]}>{title}</h2>
          <h4 className={styles["delete-modal__subtitle"]}>
            {subtitle}
          </h4>
        </div>
        <div className={styles["delete-modal__controls-wrapper"]}>
          <LightButton textSize={14} onClick={onDeclineClick}>
            Cancel
          </LightButton>
          <PrimaryButton type="button" textSize={14} onClick={onAcceptClick}>
            Delete
          </PrimaryButton>
        </div>
      </div>
    </ModalWrapper>
  );
};
export default DeleteModal;
