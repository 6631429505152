import styles from "./common-button.module.scss";

interface LightButtonInterface {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  children: string;
  onClick?: () => void;
  textSize?: number;
}

const LightButton = ({ type, children, textSize, onClick }: LightButtonInterface) => {
  return (
    <button
      type={type ?? "button"}
      className={styles["secondary"]}
      onClick={onClick}
      style={{fontSize: textSize}}
    >
      {children}
    </button>
  );
};

export default LightButton;
