import cn from "classnames";
import { Form, FormikProvider, useFormik } from "formik";
import { useSelector } from "react-redux";

import { RootStateInterface } from "reducer";

import LightButton from "components/buttons/LightButton/LightButton";
import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";
import ModalWrapper from "components/modals/ModalWrapper/ModalWrapper";

import styles from "./form-modal.module.scss";

interface FormModalInterface {
  title?: string;
  description?: string;
  warningDescription?: boolean;
  disableClose?: boolean;
  acceptButtonLabel?: string;
  declineButtonLabel?: string;
  acceptButtonAction?: () => void;
  declineButtonAction?: () => void;
  initialValues: any;
  onSubmit: () => void;
  formContent?: JSX.Element;
  formContentWithContext?: (setFieldValue, values) => JSX.Element;
  validationSchema?: any;
}

const FormModal = () => {
  const {
    title,
    description,
    warningDescription,
    disableClose,
    acceptButtonLabel,
    declineButtonLabel,
    acceptButtonAction,
    declineButtonAction,
    initialValues,
    onSubmit,
    formContentWithContext,
    formContent,
    validationSchema
  } = useSelector<RootStateInterface, FormModalInterface>(
    (state) => state.modal.modalProps
  );

  const formik = useFormik({
    validateOnChange: true,
    initialValues,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema,
    onSubmit
  });

  const handleFormSubmit = () => {
    if (acceptButtonAction) {
      acceptButtonAction();
    }
    formik.submitForm();
  };

  return (
    <ModalWrapper
      className={styles["modal-container"]}
      disableClose={disableClose}
      isSmall
    >
      <div className={styles["form-modal"]}>
        <div className={styles["form-modal__text-container"]}>
          <h2 className={styles["form-modal__title"]}>{title}</h2>
          {description && (
            <h4
              className={cn(styles["form-modal__description"], {
                [styles["form-modal__description-warning"]]: warningDescription
              })}
            >
              {description}
            </h4>
          )}
        </div>
        <FormikProvider value={formik}>
          <Form className={styles["form"]}>
            <div className={styles["form__values-wrapper"]}>
              {formContent ??
                formContentWithContext(formik.setFieldValue, formik.values)}
            </div>
            <div className={styles["form-modal__controls-wrapper"]}>
              <LightButton textSize={14} onClick={declineButtonAction}>
                {declineButtonLabel ?? "Cancel"}
              </LightButton>
              <PrimaryButton
                type="button"
                onClick={handleFormSubmit}
                textSize={14}
              >
                {acceptButtonLabel ?? "Accept"}
              </PrimaryButton>
            </div>
          </Form>
        </FormikProvider>
      </div>
    </ModalWrapper>
  );
};
export default FormModal;
