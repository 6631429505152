import { useState, useEffect, useRef, useCallback } from "react";
import cn from "classnames";
import L from "leaflet";
import { renderToString } from "react-dom/server";

import { useMap, Marker } from "react-leaflet";
import { Form, FormikProvider, useFormik } from "formik";

import ClickToSetCoordinates from "./ClickToSetCoordinates";
import FormField from "components/inputs/FormField/FormField";
import IconButton from "components/buttons/IconButton/IconButton";
import PrimaryButton from "components/buttons/PrimaryButton/PrimaryButton";

import SearchIcon from "assets/icons/search.svg";
import FoundMarkerIcon from "assets/icons/pin-map.svg";

import styles from "./map-search-point.module.scss";

const MapSearchPoint = () => {
  const map = useMap();
  const [openForm, setOpenForm] = useState(false);
  const [markerVisible, setMarkerVisible] = useState(false);
  const searchRef = useRef(null);

  const MarkerIcon = L.divIcon({
    html: renderToString(<div>{<img src={FoundMarkerIcon} />}</div>),
    className: "marker-icon",
    iconSize: [12, 30],
    iconAnchor: [6, 28]
  });

  useEffect(() => {
    if (searchRef.current) {
      L.DomEvent.disableClickPropagation(searchRef.current);
      L.DomEvent.disableScrollPropagation(searchRef.current);
    }
  }, []);

  const handlePointSearch = useCallback(
    (isClick: boolean) => (values) => {
      if (values.lat && values.lng) {
        !isClick && map.setView([values.lat, values.lng], 20);
        formik.setValues(values);
        setMarkerVisible(true);
        toggleFormOpenState(true);
      }
    },
    [map]
  );

  const toggleFormOpenState = (newValue?: boolean) => {
    setOpenForm((prevState) => {
      if (newValue) {
        return newValue;
      }
      if (prevState) {
        formik.resetForm();
        setMarkerVisible(false);
      }
      return !prevState;
    });
  };

  const formik = useFormik({
    validateOnChange: true,
    initialValues: { lat: "", lng: "" },
    validateOnMount: true,
    enableReinitialize: true,
    onSubmit: (values) => handlePointSearch(false)(values)
  });

  const handleCoordinateChange = (name, e) => {
    formik.setFieldValue(name, e.target.value);
    setMarkerVisible(false);
  };

  return (
    <div className={styles["search"]} ref={searchRef}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          toggleFormOpenState();
        }}
        icon={SearchIcon}
        className={styles["icon-button"]}
      />
      <FormikProvider value={formik}>
        <Form
          className={cn(styles["form"], {
            [styles["form__active"]]: openForm
          })}
        >
          <FormField
            name={"lat"}
            placeholder="Latitude"
            onChange={(e) => handleCoordinateChange("lat", e)}
          />
          <FormField
            name={"lng"}
            placeholder="Longitude"
            onChange={(e) => handleCoordinateChange("lng", e)}
          />
          <PrimaryButton type={"submit"} children={"Search"} />
        </Form>
      </FormikProvider>
      {formik.values.lat && formik.values.lng && markerVisible && (
        <Marker
          position={[Number(formik.values.lat), Number(formik.values.lng)]}
          icon={MarkerIcon}
        />
      )}
      <ClickToSetCoordinates
        setCoordinates={(coordinates) => handlePointSearch(true)(coordinates)}
      />
    </div>
  );
};

export default MapSearchPoint;
