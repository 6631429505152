import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { LayerAlias, MULTISPECTRAL_OPTIONS } from "constant";
import features from "features";
import { MapLayerInterface as MapLayerDataInterface } from "scenes/MapPage/ducks";

import Range from "components/Range/Range";
import ShowHideButton from "components/buttons/ShowHideButton/ShowHideButton";
import MapActionBtns from "../MapActionBtns/MapActionBtns";

import styles from "./map-layer.module.scss";

interface MapLayerInterface extends MapLayerDataInterface {
  isPolygon?: boolean;
  isInit?: boolean;
  startInference?: () => void;
  addLayerAction?: () => void;
}

const MapLayer = ({
  name,
  isActive,
  alias,
  params,
  opacity,
  isPolygon,
  startInference,
  addLayerAction,
  isInit,
  rescale_max,
  rescale_min
}: MapLayerInterface) => {
  const dispatch = useDispatch();

  const multispectralOptions = useMemo(
    () => MULTISPECTRAL_OPTIONS(rescale_min, rescale_max),
    [rescale_max, rescale_min]
  );

  const onToggleLayerIsActive = useCallback(() => {
    isPolygon
      ? dispatch(features.map.actions.toggleAriaIsActive(alias))
      : dispatch(
          features.map.actions.toggleLayerIsActive({ params: { alias } })
        );
  }, [alias, dispatch, isPolygon]);

  const onLayerParamsChange = useCallback(
    (params: string, alias: string) => () => {
      dispatch(features.map.actions.setLayerParams({ params, alias }));
    },
    [dispatch]
  );

  const onChangeOpacity = useCallback(
    (alias) => (opacity) => {
      dispatch(features.map.actions.setLayerOpacity({ opacity, alias }));
    },
    [dispatch]
  );

  return (
    <div
      className={classNames(styles["layer-wrapper"], {
        [styles["layer-wrapper__poligons"]]: isPolygon
      })}
    >
      <ShowHideButton
        title={name}
        onClick={onToggleLayerIsActive}
        isActive={isActive}
        className={isPolygon && styles["small-show-hide-block"]}
        isDisabled={!isInit}
      />
      {!isPolygon && (
        <Range
          value={opacity ?? 100}
          containerClassName={styles["layer__range-container"]}
          isDisabled={!isInit || !isActive}
          onChange={onChangeOpacity(alias)}
        />
      )}
      {alias === LayerAlias.MULTISPECTRAL && (
        <div className={styles["multispectral__options"]}>
          {multispectralOptions.map((item) => {
            return (
              <button
                className={classNames(styles["multispectral__option"], {
                  [styles["multispectral__option--active"]]:
                    params === item.params && isActive
                })}
                onClick={onLayerParamsChange(item.params, alias)}
                key={`layer-param-${item.name}`}
                disabled={!isActive}
              >
                {item.name}
              </button>
            );
          })}
        </div>
      )}

      {!isPolygon && (
        <div className={styles["layer__buttons-container"]}>
          <MapActionBtns
            inferenceAction={startInference}
            isLayerInit={isInit}
            action={addLayerAction}
          />
        </div>
      )}
    </div>
  );
};
export default MapLayer;
