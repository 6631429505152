import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import features from "features";
import {
  MapLayerInterface,
  MarkerDetailsInterface,
  MarkerInterface
} from "scenes/MapPage/ducks";

import PreloaderWrapper from "components/PreloaderWrapper/PreloaderWrapper";
import Slider from "components/Slider/Slider";
import SecondaryButton from "components/buttons/Secondary/Secondary";
import DetectionTypeButton from "./components/DetectionTypeButton/DetectionTypeButton";

import closeIcon from "assets/icons/close.svg";
import messageCircleIcon from "assets/icons/message-circle.svg";
import startIcon from "assets/icons/star.svg";
import trashIcon from "assets/icons/trash.svg";

import styles from "./map-marker-details.module.scss";

interface MapMarkerDetailsPropsInterface {
  isLoadingMarkerDetails: boolean;
  isMarkerDeleteLoading: boolean;
  markerDetails?: MarkerDetailsInterface;
  activeMarker?: MarkerInterface;
  projectId: string;
  layers: Array<MapLayerInterface>;
}

const MapMarkerDetails = ({
  markerDetails,
  isLoadingMarkerDetails,
  isMarkerDeleteLoading,
  activeMarker,
  projectId,
  layers
}: MapMarkerDetailsPropsInterface) => {
  const dispatch = useDispatch();
  const [activeLayerTab, setActiveLayerTab] = useState(0);
  const [previousLayerTab, setPreviousLayerTab] = useState(null);

  const onCloseMapMarkersDetails = useCallback(() => {
    dispatch(features.map.actions.clearMarkerDetails());
  }, [dispatch]);

  useEffect(() => () => onCloseMapMarkersDetails(), [onCloseMapMarkersDetails]);

  const handleDeleteMarker = () => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "DELETE",
        modalProps: {
          title: "Delete Marker?",
          subtitle:
            "Are you sure you want to delete this marker from the map? This action cannot be undone.",
          onAcceptClick: () =>
            dispatch(
              features.map.actions.fetchDeleteMarkerRequest({
                params: { projectId, markerId: markerDetails.id },
                onSuccess: () =>
                  dispatch(
                    features.modal.actions.hideModal({
                      modalType: "DELETE"
                    })
                  )
              })
            ),
          onDeclineClick: () =>
            dispatch(
              features.modal.actions.hideModal({
                modalType: "DELETE"
              })
            )
        }
      })
    );
  };

  const handleLayerButtonClick = (index) => {
    setPreviousLayerTab(activeLayerTab);
    setActiveLayerTab(index);
  };

  return (
    <div
      className={classNames(styles["details"], {
        [styles["details--active"]]:
          !isEmpty(markerDetails) && markerDetails.isActive
      })}
      id="pointDetails"
    >
      <div className={styles["details-wrapper"]}>
        <div className={styles["details-content"]}>
          <PreloaderWrapper
            loading={
              (markerDetails?.isActive && isLoadingMarkerDetails) ||
              isMarkerDeleteLoading
            }
            isAbsolute={!isEmpty(markerDetails) && markerDetails.isActive}
          >
            <div className={styles["details__content-flex-container"]}>
              <div
                className={styles["details__close"]}
                onClick={onCloseMapMarkersDetails}
              >
                <img src={closeIcon} alt="close-details"></img>
              </div>
              <div className={styles["details__head"]}>
                {activeMarker && layers.length > 1 && (
                  <div className={styles["sensor-fusing__container"]}>
                    <span className={styles["sensor-fusing__name"]}>
                      {activeMarker.name}
                    </span>
                    <span className={styles["sensor-fusing__name"]}>
                      {Math.round(activeMarker.additional.probability * 100)}%
                    </span>
                  </div>
                )}
                <div className={styles["head__content"]}>
                  <div className={styles["head__title"]}>
                    {markerDetails?.name}
                  </div>
                  <div className={styles["head__tags"]}>
                    Протипіхотні фугасні міни
                  </div>
                  <div className={styles["head__coordinate"]}>
                    {markerDetails?.geo_data?.coordinates[0]} N{" "}
                    {markerDetails?.geo_data?.coordinates[1]} E
                  </div>
                </div>
              </div>
              {layers.length > 0 && (
                <div className={styles["details__layers-container"]}>
                  {layers.map((el, index) => (
                    <DetectionTypeButton
                      name={el.name}
                      key={`${el.alias}:${index}`}
                      color={el.color}
                      probability={Math.round(
                        activeMarker?.additional.probability * 100
                      )}
                      index={index}
                      activeLayerTab={activeLayerTab}
                      previousLayerTab={previousLayerTab}
                      onClick={handleLayerButtonClick}
                    />
                  ))}
                </div>
              )}
              <div className={styles["details__slider"]}>
                <Slider />
              </div>
              <div className={styles["details__description"]}>
                <h4 className={styles["details__description-title"]}>
                  Description
                </h4>
                <div className={styles["details__description-content-wrapper"]}>
                  <span className={styles["details__description-content-text"]}>
                    Weight:
                  </span>
                  <span className={styles["details__description-content-text"]}>
                    Approximately 80 grams
                  </span>
                  <span className={styles["details__description-content-text"]}>
                    Dimensions:
                  </span>
                  <span className={styles["details__description-content-text"]}>
                    Length – about 120 mm, width – 65 mm, height – 20 mm.
                  </span>
                  <span className={styles["details__description-content-text"]}>
                    Body material:
                  </span>
                  <span className={styles["details__description-content-text"]}>
                    Plastic.
                  </span>
                  <span className={styles["details__description-content-text"]}>
                    Color:
                  </span>
                  <span className={styles["details__description-content-text"]}>
                    Typically green or brown, allowing the mines to blend in
                    with their surroundings
                  </span>
                </div>
              </div>
              <div className={styles["details__buttons"]}>
                <SecondaryButton icon={messageCircleIcon}>
                  Сomment
                </SecondaryButton>
                <SecondaryButton icon={startIcon}>Mark</SecondaryButton>
                <SecondaryButton icon={trashIcon} onClick={handleDeleteMarker}>
                  Delete
                </SecondaryButton>
              </div>
            </div>
          </PreloaderWrapper>
        </div>
      </div>
    </div>
  );
};

export default MapMarkerDetails;
