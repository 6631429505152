import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import { reducer as toastrReducer } from "react-redux-toastr";

import { MainStateInterface } from "scenes/MainPage/ducks";
import { MapStateInterface } from "scenes/MapPage/ducks";
import { OrthophotoPlanningStateInterface } from "scenes/OrthophotoPlanningPage/ducks";
import { AuthStateInterface } from "scenes/AuthPage/ducks";
import { ImageDetectionStateInterface } from "scenes/ImageDetectionPage/ducks";
import { IModalState } from "scenes/Modal/ducks";

import features from "features";

interface IReducer {
  toastr: any;
  main: MainStateInterface;
  map: MapStateInterface;
  auth: AuthStateInterface;
  orthophotoPlanning: OrthophotoPlanningStateInterface;
  imageDetection: ImageDetectionStateInterface;
  modal: IModalState;
}

export const rootReducers = combineReducers<IReducer>({
  toastr: toastrReducer,
  main: features.main.reducer,
  map: features.map.reducer,
  auth: features.auth.reducer,
  orthophotoPlanning: features.orthophotoPlanning.reducer,
  imageDetection: features.imageDetection.reducer,
  modal: features.modal.reducer
});

export const rootSagas = function* rootSaga() {
  yield all([
    features.auth.saga(),
    features.main.saga(),
    features.map.saga(),
    features.orthophotoPlanning.saga(),
    features.imageDetection.saga()
  ]);
};

export type RootStateInterface = ReturnType<typeof rootReducers>;
