import cn from "classnames";

import styles from "./common-button.module.scss";

interface CommonButtonInterface {
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  icon?: string;
  children: string;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}

const CommonButton = ({
  type,
  children,
  icon,
  onClick,
  disabled,
  className
}: CommonButtonInterface) => {
  return (
    <button
      type={type ?? "button"}
      className={cn(styles["secondary"], className, {
        [styles["secondary--disabled"]]: disabled
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <img src={icon} alt={`${children} button icon`} />}
      {children}
    </button>
  );
};

export default CommonButton;
