import cn from "classnames";

import EyeCloseIcon from "assets/icons/eye-close.svg";
import EyeOpenIcon from "assets/icons/eye-open.svg";

import styles from "./show-hide-button.module.scss";

interface ShowHideButtonInterface {
  title: string;
  onClick?: () => void;
  className?: string;
  isActive: boolean;
  isDisabled?: boolean;
}

const ShowHideButton = ({
  title,
  onClick,
  className,
  isActive,
  isDisabled
}: ShowHideButtonInterface) => {
  const handleToggleActive = () => {
    onClick();
  };

  return (
    <div
      className={cn(
        styles["show-hide-container"],
        { [styles["show-hide-container__disabled"]]: isDisabled },
        className
      )}
      onClick={handleToggleActive}
    >
      <span className={styles["show-hide__title"]}>{title}</span>
      <span className={styles["show-hide__icon"]}>
        <img
          src={isActive ? EyeOpenIcon : EyeCloseIcon}
          alt={`button ${isActive ? "active" : "hidden"}`}
        />
      </span>
    </div>
  );
};

export default ShowHideButton;
