import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import features from "features";

import FormField from "components/inputs/FormField/FormField";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

import PlusIcon from "assets/icons/plus.svg";

const NewProjectButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleFormSubmit = useCallback(
    (values) => {
      dispatch(
        features.main.actions.createProjectRequest({
          fields: { ...values },
          onSuccess: (res) => {
            navigate(`/map/${res.id}`);
          }
        })
      );
    },
    [dispatch, navigate]
  );

  const onCreateProject = useCallback(() => {
    dispatch(
      features.modal.actions.showModal({
        modalType: "FORM",
        modalProps: {
          title: "Create project",
          description: "Please enter project name.",
          initialValues: { name: "" },
          onSubmit: handleFormSubmit,
          validationSchema: Yup.object().shape({
            name: Yup.string().required("Project name is required")
          }),
          acceptButtonLabel: "Send",
          declineButtonAction: () =>
            dispatch(
              features.modal.actions.hideModal({
                modalType: "FORM"
              })
            ),
          formContent: (
            <>
              <FormField name="name" placeholder="Project name" />
            </>
          )
        }
      })
    );
  }, [dispatch, handleFormSubmit]);

  return (
    <PrimaryButton icon={PlusIcon} onClick={onCreateProject}>
      New project
    </PrimaryButton>
  );
};

export default NewProjectButton;
