import cn from "classnames";
import { useState, useRef } from "react";

import MinusIcon from "assets/icons/minus-gray.svg";
import PlusIcon from "assets/icons/plus-gray.svg";

import styles from "./expand-block.module.scss";

interface ExpandBlockInterface {
  title: string;
  className?: string;
  children: any;
}

const ExpandBlock = ({ title, className, children }: ExpandBlockInterface) => {
  const contentRef = useRef(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState("0px");

  const handleExpandedSwitch = (e) => {
    e.preventDefault();
    setIsExpanded((prevState) => !prevState);
    setHeight(isExpanded ? "0px" : `${contentRef.current.scrollHeight}px`);
  };

  return (
    <div
      className={cn(
        styles["expand-block-container"],
        { [styles["expand-block-container__inactive"]]: !isExpanded },
        className
      )}
      onClick={(e) => handleExpandedSwitch(e)}
    >
      <div className={styles["expand-block-title-wrapper"]}>
        <span className={styles["expand-block__title"]}>{title}</span>
        <span className={styles["expand-block__icon"]}>
          <img
            src={isExpanded ? MinusIcon : PlusIcon}
            alt={`button ${isExpanded ? "active" : "hidden"}`}
          />
        </span>
      </div>
      <div
        style={{
          maxHeight: height,
        }}
        ref={contentRef}
        onClick={(e) => e.stopPropagation()}
        className={styles["expand-block__content"]}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandBlock;
